import { FC, ReactNode } from 'react';

import { useAuth } from 'src/hooks';
import { RoleCode } from 'src/utils/types';

interface ConditionalProps {
  children: ReactNode;
  roleCodes?: RoleCode[];
}

export const ConditionalRender: FC<ConditionalProps> = ({
  children,
  roleCodes,
}) => {
  const { user } = useAuth();
  const userRoleCodes = user.roles?.map((role) => role.code);

  const hasRole = roleCodes?.some((roleCode) =>
    userRoleCodes?.includes(roleCode)
  );
  // if (hasRole) return <>{children}</>;
  return <>{children}</>;

  return null;
};
