import {
  alpha,
  Badge,
  Button,
  Drawer,
  experimentalStyled,
  IconButton,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Assignment } from '@mui/icons-material';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Sidebar } from 'src/content/Home/Sidebar';
import { ConditionalRender } from 'src/components/Guards';

const DrawerWrapperMobile = experimentalStyled(Drawer)(
  ({ theme }) => `
      width: 350px;
      flex-shrink: 0;
  
    & > .MuiPaper-root {
          width: 370px;
          z-index: 3;
          background: ${theme.colors.gradients.blue3};
          color: ${theme.colors.alpha.white[100]};
    }
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const HeaderTasks = () => {
  const [rightBar, setRigthBar] = useState(false);
  const handleRigthBar = () => {
    setRigthBar(!rightBar);
  };
  const theme = useTheme();
  const sidebarContent = (
    <Scrollbars autoHide>
      <Sidebar />
    </Scrollbars>
  );

  return (
    <>
      <DrawerWrapperMobile
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open={rightBar}
        onClose={handleRigthBar}
      >
        {sidebarContent}
      </DrawerWrapperMobile>

      <Tooltip arrow title="Cargas">
        <Badge
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiBadge-badge': { fontSize: 10, height: 18, minWidth: 18 },
          }}
        >
          <IconButtonWrapper
            color="warning"
            onClick={handleRigthBar}
            sx={{
              background: alpha(theme.colors.warning.main, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.warning.main,

              '&:hover': {
                background: alpha(theme.colors.warning.main, 0.2),
              },
            }}
          >
            <Assignment fontSize="small" />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>
    </>
  );
};

export default HeaderTasks;
