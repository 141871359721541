export const config = {
  server: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  auth: {
    workspaceId: process.env.REACT_APP_WORKSPACE_ID,
  },
  headers: {
    WORKSPACE_HEADER: 'x-workspace-id',
    PROGRAM_HEADER: 'x-program-id',
  },
};
