import { Box } from '@mui/material';

import Tasks from './Tasks';
import HeaderMerlin from './Merlin';

function HeaderButtons() {
  return (
    <Box
      style={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          mr: 1,
        }}
      >
        <HeaderMerlin />
      </Box>
      <Tasks />
    </Box>
  );
}

export default HeaderButtons;
