import {
  alpha,
  Badge,
  IconButton,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const HeaderMerlin = () => {
  const theme = useTheme();
  return (
    <>
      <Tooltip arrow title="Merlin">
        <Badge
          variant="dot"
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiBadge-badge': { fontSize: 10, height: 18, minWidth: 18 },
          }}
        >
          <IconButtonWrapper
            color="warning"
            onClick={() => window.open('https://epic.101grados.tech', '_blank')}
            sx={{
              background: alpha(theme.colors.primary.main, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.warning.main,

              '&:hover': {
                background: alpha(theme.colors.primary.main, 0.2),
              },
            }}
          >
            {/* <Assignment fontSize="small" /> */}
            <img
              src="https://storage.googleapis.com/lala4/store/files/fav-merlin-1-1721681703043.png"
              alt="Merlin"
              style={{
                width: '25px',
                height: '25px',
              }}
            />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>
    </>
  );
};

export default HeaderMerlin;
