import { useReducer } from 'react';
import { useSnackbar } from 'notistack';

import { useApi } from '../../../hooks/useApi';

type HandleStatus = {
  type: 'HANDLE_STATUS';
  payload: {
    status: 'idle' | 'uploading';
  };
};

type HandleUpdate = {
  type: 'HANDLE_UPDATE';
  payload: {
    field: string;
    value: string;
  };
};

type Actions = HandleStatus | HandleUpdate;

type State = {
  status: 'idle' | 'uploading';
  type: 'COBRAR' | 'PAGAR';
};

const initialState: State = {
  status: 'idle',
  type: 'PAGAR',
};

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'HANDLE_STATUS': {
      return {
        ...state,
        status: action.payload.status,
      };
    }
    case 'HANDLE_UPDATE': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    default:
      return state;
  }
};

export interface ReducerValue extends State {
  uploadBillsFile: (file: File, reset?: Function) => void;
  isUploading: boolean;
  handleUpdate: (field: string, value: string) => void;
}

export const useCustomReducer = (): ReducerValue => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { post } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const uploadBillsFile = async (file: File, reset?: Function) => {
    try {
      dispatch({
        type: 'HANDLE_STATUS',
        payload: {
          status: 'uploading',
        },
      });
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', state.type);
      await post('/urco/uploads/bills', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      reset && reset();
      enqueueSnackbar('¡Archivo subido exitosamente!', { variant: 'success' });
      dispatch({
        type: 'HANDLE_STATUS',
        payload: {
          status: 'idle',
        },
      });
    } catch (error) {
      console.error('uploadBillsFile() ->', error);
      dispatch({
        type: 'HANDLE_STATUS',
        payload: {
          status: 'idle',
        },
      });
    }
  };

  const handleUpdate = (field: string, value: string) => {
    dispatch({
      type: 'HANDLE_UPDATE',
      payload: {
        field,
        value,
      },
    });
  };

  return {
    ...state,
    uploadBillsFile,
    isUploading: state.status === 'uploading',
    handleUpdate,
  };
};
